










































































































































import { SfButton } from "@storefront-ui/vue";
import { defineComponent, ref, useFetch } from "@nuxtjs/composition-api";
import CustomBlocks from "~/components/CustomBlocks.vue";
import HeaderLogo from "~/components/HeaderLogo.vue";
import IconPinAddress from "~/components/General/IconPinAddress.vue";
import CopyRight from "~/components/Footer/CopyRight.vue";
import IconArrowRt from "~/components/General/IconArrowRt.vue";
import IconPlus from "~/components/General/IconPlus.vue"
import IconMinus from "~/components/General/IconMinus.vue"
import { useTopBar } from "./TopBar/useTopBar";
import type { CmsBlock } from '~/modules/GraphQL/types';
import CustomBlock from '~/components/CustomBlock.vue';
import { useContent } from "~/composables";
import Preload from "~/components/Preload.vue";

export default defineComponent({
  components: {
    SfButton,
    CustomBlocks,
    HeaderLogo,
    IconPinAddress,
    CopyRight,
    IconArrowRt,
    IconPlus,
    IconMinus,
    CustomBlock,
    Preload,
    StoreSwitcher: () => import("~/components/StoreSwitcher.vue"),
  },
  setup() {
    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();
    const {
      loadBlocks,
    } = useContent();
    const blocks = ref<CmsBlock[]>([]);
    const isLoaded = ref(false);
    useFetch(async () => {
      blocks.value = await loadBlocks({
        identifiers: [
          'footer-content-logo',
          'footer-content-about-us',
          'footer-content-more-service',
          'footer-content-social',
          'footer-social',
          'footer-logo-payment',
        ]
      });
      isLoaded.value = true;
    });
    return {
      hasStoresToSelect,
      blocks,
      isLoaded,
    };
  },
  methods: {
    ftdropdown(section) {
      if (section === 'menu') {
        this.isActiveMenu = !this.isActiveMenu;
      } else if (section === 'service') {
        this.isActiveService = !this.isActiveService;
      } else if (section === 'support') {
        this.isActiveSupport = !this.isActiveSupport;
      }
    },
  },
  data() {
    return {
      isActiveMenu: false,
      isActiveService: false,
      isActiveSupport: false,
    };
  },
});
